.main-container {
	padding: 4% 12%;
	box-sizing: border-box;
	background:linear-gradient(90deg,#C585B3,#632A50);
	width: 100%;
	display: block;
}

.product-preview {
	margin: 50px auto;
}

.carousel-product {
	width: 100%;
}

.carousel-product img{
	width: 100%;
	max-width: 600px;
	max-height: 50vh;
	border-radius: 10px;
}
.product-data {
	margin: 50px auto;
}

.product-title{
	font-size: 48px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 16px;
}

.product-description {
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 16px;

}

.product-prices {
	display: flex;
	justify-content: center;
	font-size: 25px;
	margin-top: 0;
	margin-bottom: 16px;
}

.product-prices h3 {
	margin: 0 8px 16px;
}

.product-section-heading{
	margin-top: 0;
	margin-bottom: 16px;
}

.sizes-section {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0;
	margin-bottom: 16px;
}

.stock-section {
	margin-top: 0;
	margin-bottom: 16px;
}

.product-size {
	margin: 0 8px 16px;
	width: 100%;
	max-width: 80px;

	color: #FFDCE5;
	font-size: 20px;
	font-weight: 300;

	border: 2px solid #FFDCE5;;
	border-radius: 8px;
}

.product-size-selected {
	margin: 0 8px 16px;
	width: 100%;
	max-width: 80px;

	color: #632A50;
	font-size: 20px;
	font-weight: 300;

	border: 2px solid #632A50;
	border-radius: 8px;
	background-color: #FFDCE5;
}

.redirect{
	margin: 4% 0;
	color: #FFDCE5;
}

.redirect a{
	padding: 4px;
	text-decoration: none;
	color: #FFDCE5;
	text-transform: capitalize;
	font-size: 18px;
}

.btn-details {
	background-color:#C585B3;
    border: 0;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
    height: 30px;
    width: 90px;
}