.shop{
    width:100%;
}

.btn {
    width: 120px;
    color: #C585B3;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
}

.btn-primary {
    color: #fff;
    background-color: #C585B3;
}

.title-section{
    padding: 50px 0;
    width: 100%;
    background: linear-gradient(90deg,#632A50,#C585B3);
    text-align: center;
    color: #FFDCE5;
    -webkit-text-stroke: 1px #E9CE81;
    font-family: 'Mulish', sans-serif;
    text-transform: capitalize;
}

.title-section h1 {
    font-size: max(6vw, 3em, 3rem);
}

.counter-search{
    margin: 10px 0;
    text-align: center;
}

.counter-search input {
    width: 220px;
    display: inline-block;
    vertical-align: middle;
}

.counter-search p {
    padding: 0 20px;
    display: inline-block;
    justify-content: center;
}

.products-grid {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
    grid-auto-columns: minmax(100px, auto);
}

.img-product {
    width: 250px;
    height: 250px;
}