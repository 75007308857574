.home{
    width: 100%;
}

.logo-section{
    width: 100%;
    background: linear-gradient(90deg,#632A50,#C585B3);
    vertical-align: middle;
    display: table-cell;
}

.logo-section img{
    width: 40%;
    display: block;
    margin: 0 auto;
    
}

.logo{
    font-size: 50px;
    margin: 20px 0;
}

.carousel-home {
	width: 100%;
}

.carousel-home img{
    width: 100%;
    max-width: 1300px;
	height: auto;
	border-radius: 10px;
}

.carousel-home h1 {
    font-size: max(4vw, 2em, 2rem);
}

.mid-section{
    padding: 40px 0;
    margin: 0 auto;
}

.col{
    padding: 20px;
    color: #333;
}

