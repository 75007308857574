.cart-section{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.cart-products {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-cart-less {
    background-color: #d6a8c9;
    border: 0;
    border-radius: 5px;
    height: 30px;
    width: 30px;
}

.btn-cart-more {
    background-color: #C585B3;
    border: 0;
    border-radius: 5px;
    height: 30px;
    width: 30px;
}

.btn-cart {
    background-color: #C585B3;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 35px;
}

.btn-cart ion-icon {
    color: #fff;
}

ion-icon {
    color: #632A50;
}

@media only screen and (max-width: 978px) {
    .cart-item {
        display: flex;
        flex-direction: column;
    }
}