
.title {
    margin: 48px 0;
}
.checkout-container {
    width: 100%;;
}
.contact-card {
    margin-top: 32px;
}
.resume-card{
    margin-top: 32px;
}

.paypal-card{
    border: 0;
    margin-top: 32px;
}